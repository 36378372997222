import React, { useState } from "react";
import logo from "../../assets/images/logo_2x.png";
import SignInForm from "./SignInForm";
import { NavLink } from "react-router-dom";

export const LoginPage = () => (
  <div>
    <div className="non--login">
      <div className="login-form">
        <div className="logo_img">
          <img src={logo} alt="F" />
          <h1>
            <span>FEST</span>FRWRD
          </h1>
        </div>

        <div className="login-form--inner">
          <h2>Login-STAGE</h2>
          <SignInForm />
          <div className="row">
            <div className="col-6">
              <div className=" check-remember">
                <div className="checkBoxButton">
                  <input
                    id="optradio"
                    type="checkbox"
                    className="form-check-input"
                    name="optradio"
                  />{" "}
                  <label htmlFor="optradio" className="form-check-label">
                    Remember Me
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6 text-right">
              <NavLink to="/forgot-pin" className="link-text-small">
                Forgot pin?
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer">Copyright. All rights reserved 2019</div>
  </div>
);
